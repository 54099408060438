import { Icon, IconEnum, Text, Color } from '../..';
import { inject, observer } from 'mobx-react';
import { IUserStore, IClientStore, IEvaluationPeriodStore } from '../../../stores';
import { ProfileImage } from '../ProfileImage/ProfileImage';
import { Spacer } from '../../layout';
import { useAdaptiveLayout, useOnClickOutside } from '../../../utils/hooks';
import { useHistory, Link } from 'react-router-dom';
import { UserRole } from '../../../types';
import React, { useState, useRef } from 'react';
import styles from './Header.module.css';
import config from '../../../config';

interface Props {
  userStore?: IUserStore;
  clientStore?: IClientStore;
  evaluationPeriodStore?: IEvaluationPeriodStore;
}

export function HeaderWithoutStore(props: Props) {
  const userStore = props.userStore!;
  const clientStore = props.clientStore!;
  const evaluationPeriodStore = props.evaluationPeriodStore!;
  const history = useHistory();
  const [showProfile, toggleProfile] = useState(false);
  const [showEvaluationPeriodList, toggleEvaluationPeriodList] = useState<boolean>(false);
  const [showCycleList, toggleCycleList] = useState<boolean>(false);
  const { isDesktop } = useAdaptiveLayout();

  const cycleRef = useRef<HTMLDivElement>(null);
  const profileRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(cycleRef, () => {
    toggleCycleList(false);
    toggleEvaluationPeriodList(false);
  });

  useOnClickOutside(profileRef, () => toggleProfile(false));

  return (
    <header className={styles.header}>
      <div className={styles.navigation}>
        <Link to="/" className={styles.logo}>
          Plaudify
          <Icon icon={IconEnum.HIGH5} size={isDesktop ? 32 : 24} />
        </Link>

        {renderEvaluationPeriod()}
      </div>

      <div className={styles.menu}>
        {renderTeamButton()}
        {renderProfile()}
      </div>
    </header>
  );

  function renderEvaluationPeriod()
  {
    return (
      <div className={styles.period} ref={cycleRef}>
        <button onClick={() => toggleEvaluationPeriodList(true)}>
          <strong>{clientStore.evaluationPeriod.label}</strong>
        </button>

        <button onClick={() => toggleCycleList(true)}>
          <span className={styles.evaluation_period}>
            {clientStore.checkInCycle.label}
          </span>
        </button>

        {showEvaluationPeriodList &&
          <div className={styles.period_options}>
            {evaluationPeriodStore.evaluationPeriods.map(period => (
              <button
                onClick={() => {
                  clientStore.setEvaluationPeriod(period)
                  toggleEvaluationPeriodList(false);
                }}
                key={period.id}
              >
                { period.label }
              </button>
            ))}
          </div>
        }

        {showCycleList &&
          <div className={styles.cycle_options}>
            {evaluationPeriodStore.checkInCycles
              .filter(cycle =>
                  cycle.evaluation_period_id === clientStore.evaluationPeriod.id &&
                  new Date(cycle.start_date) <= new Date()
                )
              .map(cycle => (
                <button
                  onClick={() => {
                    clientStore.setCheckInCycle(cycle)
                    toggleCycleList(false);
                  }}
                  key={cycle.id}
                >
                  { cycle.label }
                </button>
              ))
            }
          </div>
        }
      </div>
    );
  }

  function renderTeamButton() {
    if (userStore.isManager) {
      return (
        <>
          <Link to="/" className={styles.team}>
            <span className={styles.team_icon}>
              <Icon icon={IconEnum.TEAM} size={24} />
            </span>
            {isDesktop && (
              <>
                <Spacer horizontal={16} />
                <Text bold>Team</Text>
              </>
            )}
          </Link>

          <Spacer horizontal={isDesktop ? 32 : 16} />
        </>
      );
    }
  }

  function renderProfile() {
    return (
      <div className={styles.profile} ref={profileRef}>
        <button
          type="button"
          onClick={handleToggleProfile}
          className={styles.profile_button}>
          <ProfileImage name={userStore.user.name} />

          {isDesktop && (
            <>
              <div className={styles.profile_details}>
                {userStore.hasManagerRole && userStore.hasEmployeeRole && (
                  <Text size={11} color={Color.GREY}>
                    {userStore.role} view
                  </Text>
                )}
                <Text bold size={14}>
                  {userStore.user.name}
                </Text>
              </div>
              <Icon
                icon={showProfile ? IconEnum.CHEVRON_UP : IconEnum.CHEVRON_DOWN}
                size={12}
              />
            </>
          )}
        </button>

        {showProfile && (
          <div className={styles.profile_options}>
            {(userStore.isManager || userStore.isAdmin) && userStore.hasEmployeeRole && (
              <button onClick={() => switchView('employee')}>
                <Text size={11} color={Color.GREY}>
                  Switch to
                </Text>
                Employee view
              </button>
            )}

            {(userStore.isEmployee || userStore.isAdmin) && userStore.hasManagerRole && (
              <button onClick={() => switchView('manager')}>
                <Text size={11} color={Color.GREY}>
                  Switch to
                </Text>
                Manager view
              </button>
            )}

            {(userStore.isEmployee || userStore.isManager) && userStore.hasAdminRole && (
              <button onClick={() => switchView('admin')}>
                <Text
                  size={11}
                  color={Color.GREY}
                >Switch to</Text>
                Admin View
              </button>
            )}

            {userStore.hasAdminRole && (
              <button onClick={gotoSettings}>Application settings</button>
            )}

            {userStore.hasManagerRole && (
              <>
                <button onClick={gotoReports}>Reports</button>
              </>
            )}

            <button onClick={gotoProfile}>Profile settings</button>

            <button onClick={gotoFeedback}>Submit Feedback</button>

            <button onClick={gotoUserGuide}>User Guide</button>

            <button onClick={logout}>Logout</button>
          </div>
        )}
      </div>
    );
  }

  function handleToggleProfile(e: React.MouseEvent) {
    e.stopPropagation();
    toggleProfile(!showProfile);
  }

  function logout() {
    userStore.logout();
    history.push('/');
  }

  function gotoProfile() {
    history.push('/profile');
  }

  function gotoSettings() {
    history.push('/settings');
  }

  async function switchView(role: UserRole = 'employee') {
    history.push('/');
    await userStore.changeRole(role);
  }

  function gotoReports() {
    history.push('/reports');
  }

  function gotoFeedback() {
    window.location.href = `${config.WEBSITE_URL}/review`;
  }

  function gotoUserGuide() {
    window.location.href = 'https://plaudify.com/wp-content/uploads/2025/02/plaudify-user-guide-v1.pdf';
  }
}

export const Header = inject(
  'userStore',
  'clientStore',
  'evaluationPeriodStore'
)(observer(HeaderWithoutStore));
